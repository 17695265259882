import { useRouter } from 'next/router';
import styled from 'styled-components';

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { Colors } from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';
import Header from '@src/landing/components/NewHeader/Header';
import MainLayout from '@src/landing/layouts/LandingLayout/LandingLayout';

import Footer from '@landing/components/Footer/Footer';

const texts = {
    title: {
        ru: 'Страница не найдена',
        en: 'Page not found',
        lt: 'Puslapis nerastas',
        uz: 'Sahifa topilmadi',
    },
    paragraph: {
        ru: 'Похоже, этой страницы не существует!',
        en: 'It seems that this page does not exist!',
        lt: 'Atrodo, šio puslapio nėra!',
        uz: 'Ushbu sahifa mavjud emas!',
    },
    button: {
        ru: 'Вернуться на главную',
        en: 'Return to home page',
        lt: 'Grįžti į pagrindinį puslapį',
        uz: 'Asosiy sahifaga qaytish',
    }
};

export default function ErrorPage () {

    const {language} = useAppSelector(state => state.generalStatesSlice);
    const router = useRouter();

    return (
        <MainLayout>
            <div className={`container`}>
                <div className={`container__content banner error`}>
                    <Header isWhiteTheme={true}/>
                    <Content>
                        <ErrorIcon/>
                        <ErrorText>
                            <ErrorTitle>
                                {texts.title[language]}
                            </ErrorTitle>
                            <ErrorParagraph>
                                {texts.paragraph[language]}
                            </ErrorParagraph>
                            <Button isWide onClick={() => router.push('/')}>
                                {texts.button[language]}
                            </Button>
                        </ErrorText>
                    </Content>
                </div>
            </div>
            <Footer/>
        </MainLayout>
    );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ErrorIcon = styled.div`
  width: 100%;
  max-width: 395px;
  height: 248px;
  background: url('/landing/erros/404.svg') no-repeat center;
`;

const ErrorText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ErrorTitle = styled.span`
  ${typography.default};
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  color: ${Colors.GRAY_900};
  text-align: center;
`;

const ErrorParagraph = styled.span`
  ${typography.default};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: ${Colors.GRAY_900};
`;